import React, { useEffect, useState, useContext } from "react"
import * as kompetencerStyles from "../styles/kompetencer.module.css"
import { gsap } from "gsap/dist/gsap"
import useSound from "use-sound"
import ToggleSound from "../sounds/toggle-button.mp3"
import { GlobalContext } from "../components/soundToggle.js"

const CardList = ({ cards, indexUpdate }) => {
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [activeIndex, setActiveIndex] = useState(0)
  const [play] = useSound(ToggleSound, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })

  useEffect(() => {
    gsap.set("div.active", { x: 17, y: -10, z: 40 })
  }, [])

  const handleEnterKey = e => {
    e.currentTarget.click()
  }

  const keyListenersMap = new Map([[13, handleEnterKey]])
  function keyListener(e, index) {
    // get the listener corresponding to the pressed key
    const listener = keyListenersMap.get(e.keyCode)
    // call the listener if it exists
    return listener && listener(e, index)
  }

  const handleClick = (card, indexNumber) => {
    if (indexNumber !== activeIndex) {
      gsap.to(card, {
        duration: 0.5,
        x: 17,
        y: -10,
        z: 40,
        ease: "elastic.out(1, 0.3)",
      })
      gsap.to("div.active", {
        duration: 0.15,
        x: 0,
        y: 0,
        z: 0,
        ease: "Sine.easeInOut",
      })
    }
    setActiveIndex(indexNumber)
  }

  const handleClickFunctions = (e, index) => {
    e.preventDefault()
    play()
    handleClick(e.currentTarget, index)
    indexUpdate(cards, index)
  }

  const skillCards = cards.map((card, index) => (
    <figure key={card.id} id={card.liId}>
      <div
        title={card.title}
        role="button"
        tabindex="0"
        onClick={e => handleClickFunctions(e, index)}
        onKeyPress={e => keyListener(e, index)}
        className={activeIndex === index ? "active" : ""}
      >
        <img src={card.image} alt={card.imageAlt} height="86" width="86" />
      </div>
    </figure>
  ))

  return (
    <div id={activeIndex} className={kompetencerStyles.skillsWrapper}>
      {skillCards}
    </div>
  )
}

export default CardList
