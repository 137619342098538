// extracted by mini-css-extract-plugin
export var Display1 = "kompetencer-module--Display1----tnR";
export var blob1 = "kompetencer-module--blob1--wJq0d";
export var blob2 = "kompetencer-module--blob2--fZZLL";
export var blob3 = "kompetencer-module--blob3--79o7C";
export var blob4 = "kompetencer-module--blob4--xDn60";
export var cardSkills = "kompetencer-module--cardSkills--sZtUy";
export var cardSkillsWrapper = "kompetencer-module--cardSkillsWrapper--JXW55";
export var cover = "kompetencer-module--cover--RziYN";
export var dotPattern1 = "kompetencer-module--dotPattern1--5oinX";
export var dotPattern2 = "kompetencer-module--dotPattern2---oEeL";
export var fadeFrame = "kompetencer-module--fadeFrame--84Jqf";
export var pageNumber = "kompetencer-module--pageNumber--DghUr";
export var skillLogo = "kompetencer-module--skillLogo--j-k44";
export var skillsWrapper = "kompetencer-module--skillsWrapper--YQ3ms";