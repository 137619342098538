import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import BlobGradient from "../assets/blobWithGradient1.inline.svg"
import BlobClip from "../assets/clipBlob.inline.svg"
import Blob from "../assets/blob.inline.svg"
import * as kompetencerStyles from "../styles/kompetencer.module.css"
import Dotpattern from "../assets/dot_pattern.inline.svg"
import SwipeDown from "../assets/swipe_down_anim.inline.svg"
import ScrollDown from "../assets/scroll_down_anim.inline.svg"
import NextArrow from "../components/nextArrow.js"
import PreviousArrow from "../components/previousArrow.js"
import LanguageSwitch from "../components/languageSwitch.js"
import ThemeSwitch from "../components/themeSwitch.js"
import SoundButton from "../components/soundToggle.js"
import CardList from "../components/skillCards.js"
import Page05 from "../assets/page05.inline.svg"
import Seo from "../components/seo.js"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import { gsap } from "gsap/dist/gsap"
import { usePrefersReducedMotion } from "../components/prefersReducedMotion.js"
import {
  floating,
  swipeDown,
  scrollDownMouse,
} from "../components/GSAPanimations.js"
import useDeviceDetect from "../components/deviceDetect.js"

const Kompetencer = ({ location }) => {
  const { language } = useI18next()
  const prefersReducedMotion = usePrefersReducedMotion()

  const { isMobile } = useDeviceDetect()

  useEffect(() => {
    let scrollStorage = window.localStorage.getItem("scroll")
    if (scrollStorage === null) {
      scrollStorage = 1
    } else if (scrollStorage < 20) {
      scrollStorage = JSON.parse(scrollStorage) + 1
    }
    window.localStorage.setItem("scroll", scrollStorage)
    const hand1 = document.querySelector("#hand1")
    const arrow1 = document.querySelector("#arrow1")
    const mouse = document.querySelector("#mouse")
    const scrollWheel = document.querySelector("#scrollWheel")
    const arrow2 = document.querySelector("#arrow2")
    const swipeDownAnim = document.querySelector("#swipeDownAnim")
    const ScrollDownAnim = document.querySelector("#ScrollDownAnim")
    if (isMobile === undefined) return
    if (isMobile && scrollStorage < 6) {
      swipeDown(hand1, arrow1, swipeDownAnim)
    } else if (scrollStorage < 6) {
      scrollDownMouse(mouse, scrollWheel, arrow2, ScrollDownAnim)
    }
  }, [isMobile])

  useEffect(() => {
    const blob1 = document.querySelector("[class*='blob1']")
    const blob2 = document.querySelector("[class*='blob2']")
    const blob3 = document.querySelector("[class*='cover']")
    const blob4 = document.querySelector("[class*='blob4']")
    const pageNum = document.querySelector("[class*='pageNum']")
    const dot1 = document.querySelector("[class*='dotPattern1']")
    const dot2 = document.querySelector("[class*='dotPattern2']")
    gsap.killTweensOf(blob1, blob2, blob3, blob4, pageNum, dot1, dot2)
    if (prefersReducedMotion === true) {
      return
    }
    floating(blob1, blob2, blob3, blob4, pageNum, dot1, dot2)
  }, [prefersReducedMotion])

  const { t } = useTranslation()

  const cards = [
    {
      id: 1,
      liId: "li1",
      title: "React",
      divId: "sk1",
      image: "../../cards/react_card.svg",
      imageAlt: "React logo",
      description: `${t("CARDS.REACT")}`,
    },
    {
      id: 2,
      liId: "li2",
      title: "Gatsby",
      divId: "sk2",
      image: "../../cards/gatsby_card.svg",
      imageAlt: "Gatsby logo",
      description: `${t("CARDS.GATSBY")}`,
    },
    {
      id: 21,
      liId: "li21",
      title: "Next",
      divId: "sk21",
      image: "../../cards/next_card.svg",
      imageAlt: "Nextjs logo",
      description: `${t("CARDS.NEXT")}`,
    },
    {
      id: 22,
      liId: "li22",
      title: "React Native",
      divId: "sk22",
      image: "../../cards/react_native_card.svg",
      imageAlt: "React Native logo",
      description: `${t("CARDS.REACT_NATIVE")}`,
    },
    {
      id: 23,
      liId: "li23",
      title: "Storybook",
      divId: "sk23",
      image: "../../cards/storybook_card.svg",
      imageAlt: "Storybook logo",
      description: `${t("CARDS.STORYBOOK")}`,
    },
    {
      id: 24,
      liId: "li24",
      title: "Jest",
      divId: "sk24",
      image: "../../cards/jest_card.svg",
      imageAlt: "Jest logo",
      description: `${t("CARDS.JEST")}`,
    },
    {
      id: 25,
      liId: "li25",
      title: "Styled Components",
      divId: "sk25",
      image: "../../cards/styled_components_card.svg",
      imageAlt: "Styled Components logo",
      description: `${t("CARDS.STYLED_COMPONENTS")}`,
    },
    {
      id: 26,
      liId: "li26",
      title: "GraphQL",
      divId: "sk26",
      image: "../../cards/graphql_card.svg",
      imageAlt: "GraphQL logo",
      description: `${t("CARDS.GRAPH_QL")}`,
    },
    {
      id: 27,
      liId: "li27",
      title: "GraphQL Code Generator",
      divId: "sk27",
      image: "../../cards/graphql_codegen_card.svg",
      imageAlt: "GraphQL Code Generator logo",
      description: `${t("CARDS.GRAPHQL_CODEGEN")}`,
    },
    {
      id: 28,
      liId: "li28",
      title: "Redux",
      divId: "sk28",
      image: "../../cards/redux_card.svg",
      imageAlt: "Redux logo",
      description: `${t("CARDS.REDUX")}`,
    },
    {
      id: 3,
      liId: "li3",
      title: "JavaScript",
      divId: "sk3",
      image: "../../cards/javaScript_card.svg",
      imageAlt: "JavaScript logo",
      description: `${t("CARDS.JAVASCRIPT")}`,
    },
    {
      id: 20,
      liId: "li20",
      title: "TypeScript",
      divId: "sk20",
      image: "../../cards/typescript_logo_card.svg",
      imageAlt: "TypeScript logo",
      description: `${t("CARDS.TYPESCRIPT")}`,
    },
    {
      id: 4,
      liId: "li4",
      title: "CSS",
      divId: "sk4",
      image: "../../cards/css_card.svg",
      imageAlt: "CSS3 logo",
      description: `${t("CARDS.CSS")}`,
    },
    {
      id: 5,
      liId: "li5",
      title: "HTML",
      divId: "sk5",
      image: "../../cards/HTML5_card.svg",
      imageAlt: "HTML5 logo",
      description: `${t("CARDS.HTML")}`,
    },
    {
      id: 6,
      liId: "li6",
      title: "GSAP",
      divId: "sk6",
      image: "../../cards/greensock_card.svg",
      imageAlt: "Green Sock logo",
      description: `${t("CARDS.GSAP")}`,
    },
    {
      id: 8,
      liId: "li8",
      title: "WordPress",
      divId: "sk8",
      image: "../../cards/wordpress_card.svg",
      imageAlt: "WordPress logo",
      description: `${t("CARDS.WORDPRESS")}`,
    },
    {
      id: 9,
      liId: "li9",
      title: "Visual Studio Code",
      divId: "sk9",
      image: "../../cards/Visual_Studio_Code_card.svg",
      imageAlt: "Visual Studio Code logo",
      description: `${t("CARDS.VISUAL_STUDIO_CODE")}`,
    },
    {
      id: 10,
      liId: "li10",
      title: "Git",
      divId: "sk10",
      image: "../../cards/git_card.svg",
      imageAlt: "Git logo",
      description: `${t("CARDS.GIT")}`,
    },
    {
      id: 11,
      liId: "li11",
      title: "Browser Extensions",
      divId: "sk11",
      image: "../../cards/extension_card.svg",
      imageAlt: "Browser extension puzzle piece logo",
      description: `${t("CARDS.BROWSER_EXTENSION")}`,
    },
    {
      id: 12,
      liId: "li12",
      title: "Google Sheets",
      divId: "sk12",
      image: "../../cards/Google_Sheets_card.svg",
      imageAlt: "Google Sheets logo",
      description: `${t("CARDS.GOOGLE_SHEETS")}`,
    },
    {
      id: 13,
      liId: "li13",
      title: "Google Apps Script",
      divId: "sk13",
      image: "../../cards/google-apps-script_card.svg",
      imageAlt: "google Apps Script logo",
      description: `${t("CARDS.GOOGLE_APPS_SCRIPT")}`,
    },
    {
      id: 14,
      liId: "li14",
      title: "TeamViewer",
      divId: "sk14",
      image: "../../cards/Teamviewer_card.svg",
      imageAlt: "Teamviewer logo",
      description: `${t("CARDS.TEAM_VIEWER")}`,
    },
    {
      id: 15,
      liId: "li15",
      title: "Inkscape",
      divId: "sk15",
      image: "../../cards/Inkscape_card.svg",
      imageAlt: "Inkscape logo",
      description: `${t("CARDS.INKSCAPE")}`,
    },
    {
      id: 16,
      liId: "li16",
      title: "SVG",
      divId: "sk16",
      image: "../../cards/SVG_card.svg",
      imageAlt: "SVG logo",
      description: `${t("CARDS.SVG")}`,
    },
    {
      id: 17,
      liId: "li17",
      title: "GIMP2",
      divId: "sk17",
      image: "../../cards/gimp_card.svg",
      imageAlt: "Gimp logo",
      description: `${t("CARDS.GIMP2")}`,
    },
    {
      id: 18,
      liId: "li18",
      title: "Figma",
      divId: "sk18",
      image: "../../cards/Figma_card.svg",
      imageAlt: "Figma logo",
      description: `${t("CARDS.FIGMA")}`,
    },
    {
      id: 19,
      liId: "li19",
      title: "Blender",
      divId: "sk19",
      image: "../../cards/Blender_card.svg",
      imageAlt: "Blender logo",
      description: `${t("CARDS.BLENDER")}`,
    },
    {
      id: 7,
      liId: "li7",
      title: "Joomla",
      divId: "sk7",
      image: "../../cards/joomla_card.svg",
      imageAlt: "Joomla logo",
      description: `${t("CARDS.JOOMLA")}`,
    },
  ]

  const [selectedIndex, setSelectedIndex] = useState(0)

  // cards parameter is an important parameter - otherwise it fails to update on the skillCard
  const handleIndexChange = (cards, activeIndex) => {
    let SkillCard = document.querySelector(".SkillCard")
    SkillCard.classList.add("fadeOut")
    setTimeout(function () {
      setSelectedIndex(activeIndex)
      SkillCard.classList.remove("fadeOut")
    }, 500)
  }

  return (
    <>
      <Seo
        title={t("KOMPETENCER.SEO_TITLE")}
        description={t("KOMPETENCER.SEO_DESCRIPTION")}
        pathName={location.pathname}
        image="/SoMe/open_graph_skills.png"
        readingTime="2"
      />
      <PreviousArrow
        to={language === "da" ? `/grafik/` : `/${language}/grafik/`}
      />
      <NextArrow
        to={language === "da" ? `/artikler/` : `/${language}/artikler/`}
      />
      <main className="bodyWrapper">
        <LanguageSwitch />
        <SoundButton />
        <ThemeSwitch />
        <SwipeDown />
        <ScrollDown />
        <section className="card">
          <Page05 className={kompetencerStyles.pageNumber} />
          <h1 className={kompetencerStyles.Display1}>
            {t("KOMPETENCER.DISPLAY")}
          </h1>
          <h2>{t("KOMPETENCER.HEADLINE")}</h2>
          <p>{t("KOMPETENCER.BODY_TEXT")}</p>
          <p class="stylizedCapitalized">
            {t("KOMPETENCER.CLICK_ON_THE_CARDS")}
          </p>
          <BlobGradient className={kompetencerStyles.blob1} />
          <Dotpattern className={kompetencerStyles.dotPattern1} />
        </section>
        <section className="card right" style={{ zIndex: "10" }}>
          <Blob className={kompetencerStyles.blob2} />
          <BlobClip className={kompetencerStyles.blob3} />
          <div className={kompetencerStyles.cover} />
          <Blob className={kompetencerStyles.blob4} />
          <Dotpattern className={kompetencerStyles.dotPattern2} />
          <div className={kompetencerStyles.fadeFrame}>
            <div
              id={cards[`${selectedIndex}`].id}
              style={{ padding: "24px" }}
              className="SkillCard"
            >
              <div className="cardHeadline">
                <div style={{ display: "inline-flex" }}>
                  <img
                    className={kompetencerStyles.skillLogo}
                    src={cards[`${selectedIndex}`].image}
                    alt={cards[`${selectedIndex}`].imageAlt}
                    width="100%"
                    height="100%"
                  />
                  <h3 style={{ margin: "auto 1rem" }}>
                    {cards[`${selectedIndex}`].title}
                  </h3>
                </div>
              </div>
              <div>
                <p style={{ margin: "0" }}>
                  {cards[`${selectedIndex}`].description}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="card komp">
          <div className={kompetencerStyles.cardSkillsWrapper}>
            <div id={selectedIndex} className={kompetencerStyles.cardSkills}>
              <CardList cards={cards} indexUpdate={handleIndexChange} />
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Kompetencer

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
